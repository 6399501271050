import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

import "./About.css";

const About = () => {
  return (
    <Container fluid className="noPadding">
      <Row className="aboutSection padding">
        <div className=" verticalAlign">
          <Row>
            <Col xs={12} className="textCenter">
              <h1 className="theTeamTitle">THE TEAM</h1>
              <p>
                UpFox Labs is an indie games developer in Porto, Portugal.
                <br></br>
                We love what we do and dream about it every day.
              </p>
            </Col>
          </Row>
          <Container className="rowTeamMembers">
            <Row>
              <Col xs={{ span: 12 }} md={{ span: 6 }} className="padding">
                <Row className="memberBadge">
                  <Col xs={12} md={8} className="memberText">
                    <p className="memberName">Luís Almeida</p>
                    <p className="memberNickname">Doxia</p>
                    <p className="memberRole">DEV | VFX Artist</p>
                  </Col>
                  <Col xs={12} md={4} className="memberImage">
                    <Image
                      src="./assets/AvatarDoxia.png"
                      className="memberProfile"
                    ></Image>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="memberDescription">
                      Luís is a Co-director of Upfox Labs. He is a versatile
                      professional with extensive experience in 3D and VFX
                      artistry since 2012, full-stack and game development since
                      2016, teaching at Doxia Studio. With a strong passion for
                      gaming and a dedication to pushing the boundaries of
                      innovation.
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col xs={{ span: 12 }} md={{ span: 6 }} className="padding">
                <Row className="memberBadge">
                  <Col xs={12} md={8} className="memberText">
                    <p className="memberName">André ferreira</p>
                    <p className="memberNickname">Bujabans</p>
                    <p className="memberRole">Illustrator | Animator</p>
                  </Col>
                  <Col xs={12} md={4} className="memberImage">
                    <Image
                      src="./assets/AvatarBujabans.png"
                      className="memberProfile"
                    ></Image>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="memberDescription">
                      André is a Co-director of Upfox Labs. He’s been working in
                      illustration, UI and motion design since 2013, focusing in
                      vector illustrations and animation. With a distinct and
                      vibrant artistic style, André brings a unique visual flair
                      to every project he undertakes.
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </Row>
    </Container>
  );
};

export default About;
