import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "./App.css";
import CustomNavbar from "./CustomNavbar";
import Footer from "./Footer";
import Home from "./Home";
import About from "./About";
import Games from "./Games";
import Contact from "./Contact";
import ErrorPage from "./ErrorPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/games",
    element: <Games />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
]);

function App() {
  return (
    <div className="wrapper">
      <div className="App">
        <CustomNavbar></CustomNavbar>
        <RouterProvider router={router} />
        <Footer></Footer>
      </div>
    </div>
  );
}

export default App;
