import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import "./Contact.css";

const Contact = () => {
  return (
    <Container fluid className="noPadding">
      <Row className="contactSection padding">
        <div className=" verticalAlign">
          <Container className="rowTeamMembers">
            <Row>
              <Col xs={12} className="textCenter">
                <h1 className="theTitle">Contact Us</h1>
                <p>
                  Have a question about our games or our company?
                  <br></br>
                  Looking to intern with us? You just want to chat?
                  <br></br>
                  Contact us using the email below.
                </p>
                <h4 className="emailTitle">andre.ferreira@upfoxlabs.com</h4>
              </Col>
            </Row>
          </Container>
        </div>
      </Row>
    </Container>
  );
};

export default Contact;
