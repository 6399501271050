import React from "react";

import Navbar from "react-bootstrap/Navbar";
import { Container, Row, Col } from "react-bootstrap";
import "./CustomNavbar.css";

const Footer = () => {
  return (
    <Navbar fixed="bottom" className="customNavBarStyle">
      <Container>
        <Row>
          <Col className="textCenter">
            <p>© 2023 Upfox Labs. All Rights Reserved.</p>
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
};

export default Footer;
