import React from "react";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Container, Image } from "react-bootstrap";
import "./CustomNavbar.css";

import { FaTwitter, FaSteam } from "react-icons/fa";

const CustomNavbar = () => {
  return (
    <Navbar expand="md" fixed="top" className="customNavBarStyle">
      <Container>
        <Navbar.Brand href="/">
          <Image src="assets/UpFoxLogo.png"></Image>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="customNavBarStyle-links">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/games">Games</Nav.Link>
            <Nav.Link href="/about">About</Nav.Link>
            <Nav.Link href="/contact">Contact</Nav.Link>
            <Nav.Link
              href="https://twitter.com/FollyOTWizards"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FaTwitter></FaTwitter>
            </Nav.Link>
            <Nav.Link
              href="https://store.steampowered.com/app/2452320/Folly_Of_The_Wizards/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FaSteam></FaSteam>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;
