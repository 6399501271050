import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

import "./Games.css";

const Games = () => {
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Container fluid className="noPadding">
      <Row className="gameSection">
        <Col xs={{ span: 12, order: "last" }} md={{ span: 5, order: "first" }}>
          <div className="gameTextDiv colorWhite">
            <div className="gameTextContent textCenter">
              <Image src="./assets/FollyLogo.png" className="gameLogo"></Image>
              <p className="gameDescription textLeft">
                A comedic 2D roguelike action game that puts you in the shoes of
                a clumsy wizard apprentice. Join a wacky wizard cult and use
                unique spells to defeat enemies, explore procedurally generated
                dungeons, and save the world from chaos.But beware: the fate of
                the world rests on your shoulders, and even the smallest
                decision can have a huge impact. With charming hand-drawn art,
                quirky characters, and unpredictable gameplay, Folly of the
                Wizards is the perfect adventure for anyone who loves humor,
                magic, and mayhem.
              </p>
              <div className="gameButtonsDiv textLeft">
                <button
                  className="gameButton"
                  onClick={() => {
                    openInNewTab(
                      "https://store.steampowered.com/app/2452320/Folly_Of_The_Wizards/"
                    );
                  }}
                >
                  Steam Page
                </button>
                <button
                  className="gameButton"
                  onClick={() => {
                    openInNewTab("https://www.follyofthewizards.com");
                  }}
                >
                  Visit Website
                </button>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={{ span: 12, order: "first" }} md={{ span: 7, order: "last" }}>
          <div className="gameImageDiv">
            <Image src="./assets/FollyImage.png" className="gameImage"></Image>
          </div>
        </Col>
      </Row>
      <div className="lastDiv showMobile"></div>
    </Container>
  );
};

export default Games;
