import { useRouteError } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import "./Contact.css";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <Container fluid className="noPadding">
        <Row className="contactSection padding">
          <div className=" verticalAlign">
            <Container className="rowTeamMembers">
              <Row>
                <Col xs={12} className="textCenter">
                  <h1 className="theTitle">Oops!</h1>
                  <p>Sorry, an unexpected error has occurred.</p>
                  <p>
                    <i>{error.statusText || error.message}</i>
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
        </Row>
      </Container>
    </div>
  );
}
